<template>
  <component is="style">{{ style }}</component>

  <transition name="long-fade" mode="out-in" appear>
    <Preloader v-if="!contentRendered && !isPopup && !hidePreloader" :data="preloaderStyles" />
  </transition>

  <div
    v-if="showCampaign"
    ref="campaignRef"
    class="campaign"
    :style="campaignStyle"
    :class="{
      'campaign--enter': enterAnimation,
      'campaign--enter-instant': hidePreloader
    }"
    @click="onCampaignClick"
  >
    <div v-if="ready" class="campaign__pages">
      <div class="campaign__page container-fluid">
        <div v-if="isPopup" class="positioner">
          <LFSection v-for="section in sections" :key="section.id" :model="section" />
        </div>

        <LFSection v-for="section in sections" v-else :key="section.id" :model="section" />
        <AddSectionGridContainer
          v-if="
            campaignState.isEditModeActive &&
            campaignState.edit?.enabled &&
            !isPopup &&
            (campaignState.deviceType !== CampaignDeviceType.ADS ||
              (campaignState.deviceType === CampaignDeviceType.ADS &&
                campaignState.adsSizeType === CampaignAdsSizeType.RESPONSIVE))
          "
        />
      </div>
    </div>
  </div>

  <component :is="gameInitializerComp" v-if="hasGameInitializer" />

  <component :is="CampaignEditingLayer" v-if="campaignState.edit?.enabled && !isTemplatePreview" />

  <teleport to="#app">
    <NavigationMenu />
  </teleport>

  <teleport :to="(isDemo && campaignState.isEditModeActive) || isSimulating ? '.site-editor__viewer' : 'body'">
    <ScrollIndicator v-if="contentRendered && isScrollIndicatorEnabled" :color="colorScrollIndicator" />
  </teleport>

  <teleport :to="isDemo ? '.site-editor__viewer' : 'body'">
    <Popover v-if="ready" />
  </teleport>

  <Integration v-for="integration in integrations" :key="integration.state.id" :model="integration" />
</template>

<script lang="ts">
import type { PropType, Component, CSSProperties } from 'vue';
import {
  defineComponent,
  ref,
  computed,
  defineAsyncComponent,
  watch,
  onBeforeMount,
  nextTick,
  getCurrentInstance,
  onMounted
} from 'vue';
import { useHead } from '@vueuse/head';
import hotkeys from 'hotkeys-js';
import axios from 'axios';
import { SdkEventsEmitter } from '../sdk/interface';
import { SdkFlowPageChangeEvent } from '../sdk/events/flowPageChangeEvent';
import { SdkGameEndEvent } from '../sdk/events/gameEndEvent';
import useAxios from '../hooks/useAxios';
import { getAvailableSimulationDevices } from '../hooks/useDevice';
import Integration from './Integration.vue';
import ToastMessage from './ui/ToastMessage.vue';
import { useCampaignStore } from '@/src/store/campaign';
import type { CampaignData } from '@/src/typings/interfaces/data/campaign';
import type { CampaignCustomSolution } from '@/src/models/CampaignModel';
import { CampaignSolutionEnvironment, CampaignModel } from '@/src/models/CampaignModel';
import ScrollIndicator from '@/src/components/ui/ScrollIndicator.vue';
import {
  ActiveElementTypes,
  AlignContentType,
  DeviceTypes,
  SectionType,
  CampaignDeviceType,
  CampaignAdsSizeType
} from '@/src/typings/enums/enums';
import { getCookie, setCookie } from '@/src/utilities/CookieHelpers';
import { getDocumentHeight, inIframe } from '@/src/utilities/Utilities';
import { getQueryParams, parseQueryString } from '@/src/utilities/Url';
import { events } from '@/src/services/events';
import { recursivelyWaitForPromises } from '@/src/utilities/virtualDom';
import Popover from '@/src/components/components/popover/Popover.vue';
import { onResize } from '@/src/services/iframe-embed';
import { initAudio, removeAudio } from '@/src/utilities/AudioPlayer';
import { onEvent, sendEvent } from '@/src/utilities/iFrameUtils';
import ApplyReplacementTags from '@/src/components/ApplyReplacementTags.vue';
import DeviceRotater from '@/src/components/ui/DeviceRotater.vue';
import NavigationMenu from '@/src/components/navigation/NavigationMenu.vue';
import useFlow from '@/src/hooks/useFlow';
import useAnalytics from '@/src/hooks/useAnalytics';
import Preloader from '@/src/components/ui/Preloader.vue';
import { goToFictiveFlowPage } from '@/src/utilities/Flow';
import { ContentType } from '@/src/components/layout/section/SectionBaseModel';
import { useUtilityStore } from '@/src/store/utility';
import type { DeviceCookieData } from '@/src/components/components/editing/LFToolbar.vue';
import { useEditingStore } from '@/src/store/editing';

// Include our SDK. This will auto-extend window object to contain it.
import initializeSDK from '@/src/sdk';
import '@/src/globalFormRules';
import { initCookieConsent } from '@/src/services/cookieConsent';

import registerJsonRepeatable from '@/src/services/repeatable/implementations/json';
import registerRssRepeatable from '@/src/services/repeatable/implementations/rss';
import { redirect, RedirectTarget } from '@/src/services/url';
import { useRoute, useRouter } from 'vue-router';

const AddSectionGridContainer = defineAsyncComponent({
  loader: () => import('@/src/components/components/editing/AddSectionGridContainer.vue')
});

/**
 * Match key with component - We cant just do one single import
 */
const gameInitializers: { [key: string]: () => Promise<Component> } = {
  popup: () => import('@/src/components/games/popup/View.vue'),
  popupv2: () => import('@/src/components/games/popup/View.vue'),
  campaign_link: () => import('@/src/components/games/campaign-link/GameInitializer.vue'),
  poll: () => import('@/src/components/games/poll/PollGameInitializer.vue'),
  dropgame: () => import('@/src/components/games/dropgame/DropgameGameInitializer.vue'),
  personality_test: () => import('@/src/components/games/personality-test/GameInitializer.vue')
};

interface LastReloadCookie {
  campaignId: number;
  previousTimestamp: number;
}

export default defineComponent({
  name: 'Campaign',
  components: {
    Preloader,
    DeviceRotater,
    ApplyReplacementTags,
    NavigationMenu,
    Popover,
    ScrollIndicator,
    AddSectionGridContainer,
    Integration,
    ToastMessage
  },
  props: {
    data: {
      type: Object as PropType<CampaignData>,
      required: true
    },
    enterAnimation: {
      type: Boolean,
      default: true
    },
    isDemo: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const campaignStore = useCampaignStore();
    const utilityStore = useUtilityStore();
    const editingStore = useEditingStore();

    const queryParams = getQueryParams();

    const route = useRoute();
    const router = useRouter();

    let customStyle = '';

    if (props.data.config.device_type === 'ADS') {
      // Instruct the platform to wait for cookie consent.
      // This is essential for ads campaigns as they're otherwise subject to suspension on the ad networks.
      if (typeof window !== 'undefined') {
        window.cookieWait = true;
      }

      if (props.data.config.ad_size_type === 'FIXED') {
        const sizes = getAvailableSimulationDevices(
          CampaignDeviceType.ADS,
          props.data.config.ad_dimensions,
          CampaignAdsSizeType.FIXED
        )[0]?.sizes;

        if (sizes && queryParams.size && sizes.find((size) => size.id === queryParams.size)) {
          campaignStore.currentDeviceSimulateView = {
            view: queryParams.size,
            width: undefined,
            height: undefined
          };
        } else if (sizes && sizes.length > 0 && props.isDemo) {
          campaignStore.currentDeviceSimulateView = {
            view: sizes[0].id,
            width: undefined,
            height: undefined
          };
        }
      }
    } else if (queryParams.device === 'mobile' && props.isDemo) {
      campaignStore.currentDevice = DeviceTypes.MOBILE;
    } else if (queryParams.device === 'tablet' && props.isDemo) {
      campaignStore.currentDevice = DeviceTypes.TABLET;
    }

    const currentDeviceCookie = getCookie<DeviceCookieData[]>('p-device-view');

    // Force mobile simulation if campaign is only for mobile
    if (props.isDemo && props.data.config.edit?.enabled && props.data.config.device_type === 'MOBILE_ONLY') {
      campaignStore.currentDevice = DeviceTypes.MOBILE;
      const sizes = getAvailableSimulationDevices(CampaignDeviceType.MOBILE_ONLY).find(
        (device) => device.id === DeviceTypes.MOBILE
      )?.sizes;

      if (sizes) {
        campaignStore.currentDeviceSimulateView = {
          view: sizes[0].id,
          width: undefined,
          height: undefined
        };
      }
    }

    if (
      !inIframe() &&
      !queryParams.size &&
      currentDeviceCookie &&
      currentDeviceCookie.length > 0 &&
      props.isDemo &&
      props.data.config.edit?.enabled
    ) {
      currentDeviceCookie.forEach((cookieItem: DeviceCookieData) => {
        if (cookieItem.campaignId === props.data.config.campaign_id) {
          // Only overrule the currentDevice if not already set.
          // It could be forced to mobile in the condition further up or ads.
          if (!campaignStore.currentDevice) {
            campaignStore.currentDevice = cookieItem.device;
          }

          if (cookieItem.simulated) {
            campaignStore.currentDeviceSimulateView = cookieItem.simulated;
          }
        }
      });
    }

    const model = new CampaignModel(props.data);
    const campaignState = model.state;

    const additionalUserRecognitionTokens = ref<Record<string, string>>({});
    const currentPopover = ref<number | undefined | null>(null);
    const hidePreloader = ref(false);
    const ready = ref(false);
    const contentRendered = ref(false);
    const isPopup = model.state.isPopup;
    const isTemplatePreview = queryParams.template === 'overlay';
    const campaignRef = ref<HTMLDivElement | undefined>();
    let sentPageView = false;

    const languageTag = computed(() => {
      const metatags = campaignState?.config?.metatags || [];
      const languageMetatag = metatags.find((tag) => tag['http-equiv'] === 'Content-Language');
      return languageMetatag?.content || 'en';
    });

    const isSimulating = computed(() => {
      return campaignStore.currentDevice && campaignStore.model?.state.deviceType !== CampaignDeviceType.ADS;
    });

    const viewportContent = computed(() => {
      return campaignState.config?.enableZoom
        ? 'width=device-width, user-scalable=yes, minimal-ui'
        : 'width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=no, minimal-ui';
    });

    if (campaignState.isEditModeActive) {
      editingStore.isNavigatorActive = true;
    }
    const removeCustomStyle = () => {
      const getStyle = document.getElementById('custom-css');
      if (getStyle && getStyle.textContent) {
        customStyle = getStyle.textContent;
        getStyle.textContent = '';
      }
    };

    const addCustomStyle = () => {
      const getStyle = document.getElementById('custom-css');
      if (getStyle) {
        getStyle.textContent = customStyle;
      }
    };

    watch(
      () => campaignStore.popoverId,
      () => {
        currentPopover.value = campaignStore.popoverId;
      }
    );
    // custom-css
    watch(
      () => campaignStore.enableCSS,
      (isEnabled) => {
        if (isEnabled) {
          addCustomStyle();
        } else {
          removeCustomStyle();
        }
      }
    );

    const params = getQueryParams();

    if (!props.isDemo && campaignState.redirect) {
      let target: RedirectTarget | undefined;

      switch (campaignState.redirect.type) {
        case '_blank':
          target = RedirectTarget.BLANK;
          break;

        case '_top':
          target = RedirectTarget.TOP;
          break;

        case '_self':
          target = RedirectTarget.SELF;
          break;
      }

      redirect({
        url: campaignState.redirect.url,
        target
      });
    }

    /**
     * We could have put this in CampaignModel yes. But the issue is that there might
     * be custom css or other places that could change the background.
     * So to ensure that it's always getting removed i decided to just do exactly
     * what the old one did and just append it ot the body style.
     *
     * Otherwise I'm open for suggestions (Dannie).
     */
    if (params['in-campaign-link']) {
      // Embedded campaigns in campaign link should not show a background
      document.body.style.cssText += 'background: transparent !important;';
    }

    if (params['hide-loader'] || params['in-campaign-link'] || params.template === 'overlay') {
      hidePreloader.value = true;
    }

    // Add a class for when we're in iframe so that we can style it accordingly
    if (inIframe()) {
      utilityStore.addBodyClasses(['site--iframe']);
    }

    const onCampaignClick = () => {
      if (campaignState.isPreviewModeActive && campaignState.isEditModeActive) {
        campaignState.isPreviewModeActive = false;
      }
    };

    const sections = computed(() => {
      return campaignState.sections.filter((section) => {
        const visibilityConditions = section.state.config.settings.state.advanced?.visibilityCondition;

        if (section.state.config.hidden) {
          return false;
        }

        /**
         * The following code is related to the iframe-games addon which can have a parameter of onlyflow=1
         */
        if (typeof params.onlyflow !== 'undefined' && params.onlyflow && section.getAddons('gameflow').length !== 1) {
          return false;
        }

        return visibilityConditions ? visibilityConditions.check() : true;
      });
    });

    const isScrollIndicatorEnabled = computed(() => {
      const scrollIndicatorData = campaignState.layout?.scrollIndicator;
      return scrollIndicatorData?.enable && campaignStore.currentDevice === null;
    });

    const colorScrollIndicator = computed(() => {
      const scrollIndicatorData = campaignState.layout?.scrollIndicator;
      const defaultColor = '#000';
      return scrollIndicatorData?.color || defaultColor;
    });

    const campaignStyle = computed<CSSProperties>(() => {
      const campaignGrid = campaignState.layout?.grid;
      const campaignPadding = utilityStore.campaignPadding;

      if (campaignGrid) {
        const campaignAlignment = campaignGrid?.align;
        let margin: string | undefined;

        if (campaignAlignment === AlignContentType.CENTER) {
          margin = '0 auto';
        }

        if (campaignAlignment === AlignContentType.RIGHT) {
          margin = '0 0 0 auto';
        }

        return {
          ...(margin && { margin }),
          paddingTop: `${campaignPadding}px`,
          minHeight: '100%'
        };
      }

      if (campaignStore.currentDevice !== null) {
        return {
          minHeight: '100%'
        };
      }

      return {};
    });

    const handlePageView = (immediate = false) => {
      // Disable first page view for ads to avoid overburdening the analytics service with irrelevant data
      if (model.state.deviceType === CampaignDeviceType.ADS) {
        return;
      }

      if (!immediate && inIframe()) {
        setTimeout(() => {
          if (!sentPageView) {
            sentPageView = true;

            useAnalytics().handlePageView();
          }
        }, 1500);
      } else if (!sentPageView) {
        sentPageView = true;
        useAnalytics().handlePageView();
      }
    };

    const existingHotkeysFilter = hotkeys.filter;

    const hotkeysFilter = (event: KeyboardEvent) => {
      // By-pass existing filtering by hotkeys to allow enter keybindings inside of inputs
      if (
        event.key &&
        event.key.toLowerCase() === 'enter' &&
        event.target instanceof HTMLElement &&
        event.target.nodeName.toLowerCase() === 'input'
      ) {
        return true;
      }

      return existingHotkeysFilter(event);
    };

    // By-pass existing filtering by hotkeys to allow enter keybindings inside of inputs
    if (model.state.edit?.enabled) {
      hotkeys.filter = hotkeysFilter;
    }

    watch(
      () => campaignStore.flowId,
      (toId, fromId) => {
        if (!toId && !fromId) {
          return;
        }

        campaignStore.fictiveFlowPage = undefined;
        const to = toId ? model.getFlowPageModel(toId) : undefined;

        // TODO: See if we can also exclude this when there is no 'to'
        events.emit('flowPageChange', fromId ? model.getFlowPageModel(fromId) : undefined, to);

        if (to) {
          SdkEventsEmitter.emit(
            'flowPageChange',
            new SdkFlowPageChangeEvent(to, fromId ? model.getFlowPageModel(fromId) : undefined)
          );
        }
      },
      {
        immediate: true
      }
    );

    watch(
      () => campaignStore.gameEnded,
      (gameEnded, oldValue) => {
        if (!oldValue && gameEnded) {
          SdkEventsEmitter.emit('gameEnd', new SdkGameEndEvent(campaignStore.gameWinner ?? false));
        }
      }
    );

    watch(
      () => editingStore.activeTabCategory,
      () => {
        switch (editingStore.activeTabCategory) {
          case SectionType.SECTION:
            utilityStore.addBodyClasses(['site--navigator-category-sections']);
            utilityStore.removeBodyClasses(['site--navigator-category-flowpages', 'site--navigator-category-popovers']);
            break;

          case SectionType.FLOWPAGE:
            utilityStore.addBodyClasses(['site--navigator-category-flowpages']);
            utilityStore.removeBodyClasses(['site--navigator-category-sections', 'site--navigator-category-popovers']);
            break;

          case SectionType.POPOVER:
            utilityStore.addBodyClasses(['site--navigator-category-popovers']);
            utilityStore.removeBodyClasses(['site--navigator-category-sections', 'site--navigator-category-flowpages']);
            break;
        }
      }
    );

    // Only watch for data changes in the demo. Since it never changes in live URL.
    if (props.isDemo) {
      watch(
        () => props.data,
        () => {
          model.setData(props.data);
        }
      );
    }

    watch(
      () => currentPopover.value,
      () => {
        /**
         * If there is no popover open, remove the parameter pageId from URL, else add it to the url
         */
        if (!currentPopover.value) {
          const query = { ...route.query };
          delete query.pageId;
          router.push({ query });
        } else {
          router.push({
            query: {
              ...router.currentRoute.value.query,
              pageId: currentPopover.value
            }
          });
        }
      }
    );

    /**
     * Handle situations where we call go-to-next-flowpage, but there is no more.
     */
    watch(
      () => campaignStore.noMoreFlowPages,
      () => {
        if (!params['in-campaign-link'] && campaignState.config?.gameAlias !== 'landingpage') {
          const documentHeight = getDocumentHeight();

          if (campaignStore.noMoreFlowPages) {
            goToFictiveFlowPage('No more flowpages', {
              type: ContentType.TEXT,
              content: `<div class="row" style="height: ${documentHeight}px">
                          <h2></h2>
                          <div class="message-body"></div>
                        </div>`
            });
          }
        }
      }
    );

    watch(
      () => route.query.pageId,
      () => {
        if (route.query.pageId && !campaignState.isEditModeActive) {
          events.emit('popoverShow', Number(route.query.pageId));
        } else if (!campaignState.isEditModeActive) {
          events.emit('popoverHide');
        }
      },
      {
        immediate: false
      }
    );

    onBeforeMount(() => {
      if (route.params.edit) {
        campaignState.isEditModeActive = true;
      }
    });

    const hasGameInitializer =
      model.state.config?.gameAlias && typeof gameInitializers[model.state.config?.gameAlias] !== 'undefined';

    let gameInitReadyPromiseResolve: (() => void) | undefined;

    const gameInitReadyPromise = new Promise<void>((resolve) => {
      gameInitReadyPromiseResolve = resolve;

      if (!hasGameInitializer) {
        gameInitReadyPromiseResolve();
      }
    });

    /**
     * You can read more about the game initializer component in the README.md under the popup section.
     */
    const gameInitializerComp = defineAsyncComponent({
      loader: async () => {
        if (model.state.config?.gameAlias && typeof gameInitializers[model.state.config?.gameAlias] !== 'undefined') {
          const component = await gameInitializers[model.state.config?.gameAlias]();

          nextTick().then(() => {
            if (gameInitReadyPromiseResolve) {
              gameInitReadyPromiseResolve();
            }
          });

          return component;
        }

        throw new Error('Unrecognized game initializer component');
      }
    });

    const userRecognitionTokens = computed<Record<string, string>>(() => {
      const querystrings = getQueryParams();

      return { ...querystrings, ...additionalUserRecognitionTokens.value };
    });

    const hasRecognitionTokens = computed<boolean>(() => {
      if (model.state.config?.userRecognition) {
        const parameters = model.state.config.userRecognition.parameters;
        const tokens = userRecognitionTokens.value;

        for (const key in tokens) {
          if (Object.prototype.hasOwnProperty.call(tokens, key) && parameters.includes(key)) {
            return true;
          }
        }
      }

      return false;
    });

    let readyPromiseResolve: (() => void) | undefined;
    const readyPromise = new Promise<void>((resolve) => {
      readyPromiseResolve = resolve;
    });

    const currentInstance = getCurrentInstance();

    if (campaignState.isEditModeActive && route.query.pageId) {
      campaignStore.setActivePopover(undefined);
      events.emit('popoverHide');
    }

    (async () => {
      // Resolve the ready promise from the store.
      // This promise is used to indicate to the SDK that the campaign is ready.
      if (campaignStore.readyPromiseResolve) {
        campaignStore.readyPromiseResolve();
      }

      // If game contains game initializer components we need to wait for it to load before we continue loading the
      // campaign.
      await gameInitReadyPromise;

      // For some reason 3 ticks is needed before our game initializer component is fully added.
      // Don't expect me to understand...
      await nextTick();
      await nextTick();
      await nextTick();

      // Ensures that game initializer & integrations can stall rendering of the page.
      // For integrations this will wait for the component to load & register what it needs to register.
      if (currentInstance?.vnode) {
        // Wait for onBeforeEnter upwards to 6 times. This allows async components to load other async components.
        await recursivelyWaitForPromises(currentInstance.vnode, 'onBeforeEnter', 6);
      }

      // Check user recognition before campaign is shown & first flowpage is shown.
      if (model.state.config?.userRecognition) {
        onEvent<Record<string, string>>('user-recognition-tokens', (e) => {
          if (typeof e.data !== 'object') {
            return;
          }

          additionalUserRecognitionTokens.value = e.data;
        });

        sendEvent('user-recognition-ready');

        // Wait for tokens to be passed to the campaign, timeout 2 seconds.
        if (model.state.config?.userRecognition.waitForTokens) {
          await new Promise<void>((resolve) => {
            if (hasRecognitionTokens.value) {
              resolve();
              return;
            }

            const interval = setInterval(() => {
              if (hasRecognitionTokens.value) {
                window.clearInterval(interval);
                resolve();
              }
            }, 100);

            setTimeout(() => {
              window.clearInterval(interval);
              resolve();
            }, 2000);
          });
        }

        const parameters = model.state.config.userRecognition.parameters;
        const querystrings = userRecognitionTokens.value;
        const tokens: Record<string, string> = {};

        // Populate tokens object while whitelisting from accepted parameters
        for (const key in querystrings) {
          if (Object.prototype.hasOwnProperty.call(querystrings, key) && parameters.includes(key)) {
            tokens[`${key}`] = querystrings[`${key}`];
          }
        }

        // Add support for cookies. This acts as a fallback if it is not present in the querystring.
        parameters.forEach((parameter) => {
          if (!tokens[`${parameter}`]) {
            const cookieValue = getCookie(parameter);

            if (cookieValue && typeof cookieValue === 'string') {
              tokens[`${parameter}`] = cookieValue;
            }
          }
        });

        if (Object.keys(tokens).length > 0) {
          try {
            const { postDataFormData } = useAxios<{ fields: string[]; exposed?: Record<string, string> }>(
              `/api/v1/campaign/user-recognition/validate/${model.state.id}/${model.state.config.hash}`,
              {
                tokens
              }
            );

            const response = await postDataFormData();

            if (response.fields && response.fields.length > 0) {
              response.fields.forEach((field) => {
                if (model.state.config?.userRecognition?.mapping[`${field}`]) {
                  const fieldModel = model.getRegistrationFieldModel(
                    (fieldItem) =>
                      Number(model.state.config?.userRecognition?.mapping[`${field}`]) === Number(fieldItem.id)
                  );

                  if (fieldModel) {
                    fieldModel.state.ignoreValidation = true;
                    fieldModel.state.hidden = true;
                    fieldModel.state.includeInApi = false;
                  }
                }
              });
            }

            if (response.exposed) {
              for (const exposedKey in response.exposed) {
                if (
                  Object.prototype.hasOwnProperty.call(response.exposed, exposedKey) &&
                  model.state.config.userRecognition.mapping[`${exposedKey}`]
                ) {
                  // replace form field model with exposed value token value from user recognition
                  const fieldModel = model.getRegistrationFieldModel(
                    (fieldItem) =>
                      Number(model.state.config?.userRecognition?.mapping[`${exposedKey}`]) === Number(fieldItem.id)
                  );

                  if (fieldModel) {
                    // parse the exposed value to the field model, so we now can use the value as a condition
                    fieldModel.state.value = fieldModel.parseStringValue(response.exposed[`${exposedKey}`]);
                  }

                  campaignStore.addReplacementTags({
                    [`registration_field_${model.state.config.userRecognition.mapping[`${exposedKey}`]}`]:
                      response.exposed[`${exposedKey}`]
                  });
                }
              }
            }

            // Pass tokens to registration form data
            campaignStore.addStaticFormData({
              ur_tokens: tokens
            });
          } catch (e) {
            if (axios.isAxiosError<{ message: string }>(e) && e.response?.status === 400) {
              if (e.response.data.message && model.state.config.userRecognition.messages[e.response.data.message]) {
                model.state.config.userRecognition.messages[e.response.data.message].trigger();
              } else if (model.state.config.userRecognition.messages['token-invalid']) {
                model.state.config.userRecognition.messages['token-invalid'].trigger();
              }
            } else {
              throw e;
            }
          }
        } else if (
          !props.isDemo &&
          !model.state.config?.userRecognition?.allowUnrecognisedUsers &&
          model.state.config.userRecognition.messages['token-missing']
        ) {
          model.state.config.userRecognition.messages['token-missing'].trigger();
        }
      }

      // Get initial flowpage. This is done here to prevent visibility condition issues
      // if integrations extend the visibility condition service.
      if (!campaignStore.flowId && !campaignStore.fictiveFlowPage) {
        campaignStore.flowId = useFlow().getNextFlowPage() ?? undefined;
        campaignStore.fictiveFlowPage = undefined;
      }

      await nextTick();
      ready.value = true;

      if (readyPromiseResolve) {
        readyPromiseResolve();
      }

      await nextTick();

      // Ensures that sections & addons are fully loaded and ready.
      if (currentInstance?.vnode) {
        // Wait for onBeforeEnter upwards to 3 times. This allows async components to load other async components.
        await recursivelyWaitForPromises(currentInstance.vnode, 'onBeforeEnter', 3);
      }

      contentRendered.value = true;

      model.setContentReady(true);

      onResize();
      sendEvent('campaign-ready');
    })();

    let editingReadyPromiseResolve: (() => void) | undefined;

    const editingReadyPromise = new Promise<void>((resolve) => {
      editingReadyPromiseResolve = resolve;
    });

    const CampaignEditingLayer = defineAsyncComponent({
      loader: async () => {
        const component = await import('./CampaignEditingLayer.vue');

        if (editingReadyPromiseResolve) {
          editingReadyPromiseResolve();
        }

        return component;
      }
    });

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', onResize);
      onResize();

      onEvent<string>('response-url', (data) => {
        const extendedParams: Record<string, string> = {};
        const topLevelParams = parseQueryString(data.data);
        const queryParams = getQueryParams();

        campaignStore.hasResponsiveScript = true;

        // Make sure we dont have dupes, if not, add it to extendedParams
        if (Object.keys(topLevelParams).length > 0) {
          for (const topLevelParam in topLevelParams) {
            if (
              Object.prototype.hasOwnProperty.call(topLevelParams, topLevelParam) &&
              typeof queryParams[`${topLevelParam}`] === 'undefined'
            ) {
              extendedParams[`${topLevelParam}`] = topLevelParams[`${topLevelParam}`];
            }
          }
        }

        if (data && data.data) {
          utilityStore.overwrittenUrl = (data.data + '').split('#')[0];
        }

        // Set the params in store
        campaignStore.addStaticQueryParams(extendedParams);

        // After we have extended the params with top level params, we need to set replacement tags once again
        campaignStore.addReplacementTags(getQueryParams());

        model.formFields.forEach((field) => field.setInitialValue());

        handlePageView(true);
      });

      // Send event
      sendEvent('request-url', undefined, true);
    }

    // Handle case where campaign only should show in iframe
    const showCampaign = computed(() => {
      if (props.data?.config.enable_only_allow_viewing_in_iframe && !inIframe() && !props.isDemo) {
        return false;
      }

      return true;
    });

    const preloaderStyles = computed(() => {
      return campaignState.advanced?.preloader;
    });

    const integrations = computed(() => {
      return (
        campaignState.config?.integrations.filter((integration) => {
          return integration.hasVueComponent();
        }) ?? []
      );
    });

    const campaignTitle = computed<string>(() => {
      return (
        campaignState.config?.metatags?.find((meta) => {
          return meta.property === 'og:title';
        })?.content ??
        campaignState.config?.title ??
        ''
      );
    });

    const isDemo = computed(() => {
      return utilityStore.url.includes('/campaign/view/demo');
    });

    const style = computed<string>(() => {
      return `
        ${campaignState.globalStyling || ''},
        ${campaignState.campaignStyling || ''}

        ${
          !(campaignState.isEditModeActive && isDemo)
            ? `
              ${campaignState.buttonStylingHoverGlobal || ''},
              ${campaignState.buttonStylingHover || ''}
            `
            : ''
        }
      `;
    });

    const customSolutionScripts = computed<string[]>(() => {
      const projects: CampaignCustomSolution[] = campaignState.config?.customSolutions ?? [];

      return projects
        .filter((project) => project.assets.js)
        .map<string>((project) => {
          switch (project.environment) {
            case CampaignSolutionEnvironment.LOCAL:
              return `http://localhost:3000/${project.id}/index.js`;

            case CampaignSolutionEnvironment.DEV:
              return `https://${project.id}.project.dev.agency.playable.com/${project.id}/index.js`;

            case CampaignSolutionEnvironment.STAGING:
              return `https://${project.id}.project.stag.agency.playable.com/${project.id}/index.js`;

            default:
              return `https://${project.id}.project.prod.agency.playable.com/${project.id}/index.js`;
          }
        });
    });

    const customSolutionCss = computed<string[]>(() => {
      const projects: CampaignCustomSolution[] = campaignState.config?.customSolutions ?? [];

      return projects
        .filter((project) => project.assets.css)
        .map<string>((project) => {
          switch (project.environment) {
            case CampaignSolutionEnvironment.LOCAL:
              return `http://localhost:3000/${project.id}/index.css`;

            case CampaignSolutionEnvironment.DEV:
              return `https://${project.id}.project.dev.agency.playable.com/${project.id}/index.css`;

            case CampaignSolutionEnvironment.STAGING:
              return `https://${project.id}.project.stag.agency.playable.com/${project.id}/index.css`;

            default:
              return `https://${project.id}.project.prod.agency.playable.com/${project.id}/index.css`;
          }
        });
    });

    useHead({
      bodyAttrs: {
        ['class']: () => utilityStore.bodyClassList.join(' ')
      },
      title: campaignTitle,
      htmlAttrs: () => {
        return {
          class: 'ontouchstart' in window ? 'touch' : 'no-touch',
          lang: languageTag.value
        };
      },
      meta: () => {
        return [
          { 'http-equiv': 'Content-Type', content: 'text/html; charset=UTF-8' },
          {
            name: 'viewport',
            content: viewportContent.value
          },
          { name: 'format-detection', content: 'telephone=no' },
          // TypeScript is complaining below. But it is compatible.
          // TODO: Check to see if we can type this to match the wanted signature
          // @ts-ignore
          ...(campaignState.config?.metatags ? campaignState.config.metatags : {})
        ];
      },
      style: () => {
        return utilityStore.fonts.map((font) => {
          return (
            campaignState.config?.customFonts
              .filter((custom) => {
                return custom.fontFamily === font && custom.css !== '';
              })
              .reduce((acc, custom) => {
                return acc + custom.css;
              }, '') ?? ''
          );
        });
      },
      link: () => {
        const links = utilityStore.fonts.filter((font) => {
          return !campaignState.config?.customFonts.find((custom) => {
            return custom.fontFamily === font;
          });
        });

        return [
          ...links.map((font) => {
            return {
              type: 'text/css',
              rel: 'stylesheet',
              href:
                'https://fonts.googleapis.com/css?family=' +
                encodeURIComponent(font) +
                ':200,200i,300,300i,400,400i,500,500i,600,700,700i,800,800i,900,900i&display=swap',
              key: font
            };
          }),
          ...customSolutionCss.value.map((projectCss) => {
            return {
              type: 'text/css',
              rel: 'stylesheet',
              href: projectCss,
              key: projectCss
            };
          })
        ];
      },
      script: () => {
        return [
          ...(customSolutionScripts.value
            ? customSolutionScripts.value.map((projectScript) => {
                return {
                  type: 'module',
                  src: projectScript,
                  key: projectScript
                };
              })
            : [])
        ];
      }
    });

    if (campaignState.edit?.enabled) {
      const lastReload = getCookie<LastReloadCookie>('p-last-reload');
      // More than 20 seconds have passed since the last reload -> 20000
      // or new campaign is loaded
      if (
        lastReload &&
        (Date.now() - lastReload.previousTimestamp > 20000 || lastReload.campaignId !== model.state.id)
      ) {
        setCookie('p-navigator-active-tab', SectionType.FLOWPAGE);
      }

      window.addEventListener('beforeunload', () => {
        const cookieObject: LastReloadCookie = {
          campaignId: model.state.id,
          previousTimestamp: Date.now()
        };

        setCookie('p-last-reload', JSON.stringify(cookieObject));
      });
    }

    const sounds = computed<string[]>(() => {
      const gameplay = model.getGamePlayModel();
      const gameModel = gameplay?.state.settings?.game;

      // only init audio if we have sounds in our game
      if (gameModel && 'getSounds' in gameModel) {
        return gameModel.getSounds();
      }

      return [];
    });

    watch(sounds, () => {
      if (sounds.value.length > 0) {
        initAudio(sounds.value);
      } else {
        removeAudio();
      }
    });

    const handleInitAudio = () => {
      if (sounds.value.length > 0) {
        initAudio(sounds.value);
      }
      window.removeEventListener('mousedown', handleInitAudio);
      window.removeEventListener('touchstart', handleInitAudio);
    };

    onMounted(() => {
      // initial sounds on first touchevent/mouseevent
      // reduces bandwidth for campaigns not getting interacted with
      window.addEventListener('mousedown', handleInitAudio, { once: true });
      window.addEventListener('touchstart', handleInitAudio, { once: true });

      if (campaignRef.value) {
        if ('IntersectionObserver' in window) {
          // observe if section is in intersection
          const observer = new IntersectionObserver(([entry]) => {
            if (entry && entry.isIntersecting && campaignRef.value) {
              observer.unobserve(campaignRef.value);

              handlePageView();
            }
          });

          observer.observe(campaignRef.value);
        } else {
          handlePageView();
        }
      }
    });

    initializeSDK();
    initCookieConsent();
    registerJsonRepeatable();
    registerRssRepeatable();

    return {
      languageTag,
      showCampaign,
      isTemplatePreview,
      hidePreloader,
      preloaderStyles,
      isSimulating,
      model,
      onCampaignClick,
      campaignRef,
      ready,
      style,
      integrations,
      campaignStyle,
      isScrollIndicatorEnabled,
      colorScrollIndicator,
      sections,
      CampaignDeviceType,
      hasGameInitializer,
      campaignStore,
      isPopup,
      contentRendered,
      CampaignEditingLayer,
      gameInitializerComp,
      ActiveElementTypes,
      utilityStore,
      campaignState,
      campaignTitle,
      editingStore,
      viewportContent,
      CampaignAdsSizeType,
      onBeforeEnter: async () => {
        window.history.scrollRestoration = 'manual';

        await readyPromise;

        if (campaignState.edit?.enabled) {
          await editingReadyPromise;
        }
      }
    };
  }
});
</script>

<style lang="scss">
// NEVER CHANGE THIS CLASS TO MATCH THE OLD FE
// WE SHOULD NOT ADD overflow: hidden; OR  min-height: 100vh; BROKE  NEW FE IMAGES AND STUFF
// IF YOU REALLY WANT TO CHANGE THIS TO MATCH THE OLD FE - THEN GO TO DANNIE AND HE WOULD BE SUPER HAPPY EXPLAIN FOR THE 100TH TIME WHY WE SHOULD NOT
body.site--navigator-docked {
  .campaign {
    &__custom-css-button {
      transform: translateX(280px);
    }
  }
}

.campaign {
  opacity: 0;

  &--enter-instant {
    opacity: 1 !important;
    animation: none !important;
  }

  &__test-date {
    position: fixed;
    bottom: 30px;
    right: 30px;
  }
}

.site--simulate-mobile .campaign,
.site--simulate-tablet .campaign {
  overflow: hidden;
}
</style>
